import store from '@/store'
export function useCallPreviewAudioPlayer() {
  let currentAudio = null

  const playAudiosSequentially = audioConfigs => {
    if (audioConfigs.length === 0) {
      store.commit('setActiveCall', false)

      return
    }

    const playNextAudio = index => {
      if (index >= audioConfigs.length) {
        store.commit('setActiveCall', false)

        return
      }

      const delay = 0
      const base64Audio = audioConfigs[index]
      const audioData = `data:audio/mp3;base64,${base64Audio}`
      currentAudio = new Audio(audioData)

      currentAudio.play()

      currentAudio.onended = () => {
        setTimeout(() => {
          playNextAudio(index + 1)
        }, delay * 1000)
      }
    }

    store.commit('setActiveCall', true)

    playNextAudio(0)
  }

  const endPlayback = () => {
    if (currentAudio) {
      currentAudio.pause()
      currentAudio.currentTime = 0
      currentAudio = null
    }
    store.commit('setActiveCall', false)
  }

  return {
    playAudiosSequentially,
    endPlayback,
  }
}
